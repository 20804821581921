import React, { useEffect, useContext } from "react"
import styled from "styled-components"
import { rem } from "polished"
import { StoreContext } from "../store"

import Meta from "../components/meta"
import Header from "../components/header"

const ApexConnectPage = () => {
  const store = useContext(StoreContext)

  useEffect(() => {
    if (store.showBanner) {
      return store.setShowBanner(false)
    }

    return _ => {
      store.setShowBanner(true)
    }
  }, [store])

  return (
    <React.Fragment>
      <Meta title="Apex Connect" />

      <Header />

      <Container>
        <Title>Apex Connect</Title>

        <Content className="styled">
          <ApexConnectIframe src="https://apex-connect.avalanchestudios.com/portal/secondextinction"></ApexConnectIframe>

          <ApexConnectInfo>
            <h3>Oh no, another sign-up?</h3>
            <p>
              Don't worry, ours is cool: With APEX Connect, you become part of
              our special circle of friends. Signing up provides in-game
              rewards, unique game features, and ensures you'll never miss an
              update.
            </p>
            <p>
              Down the line, APEX Connect members will qualify for playtests and
              closed betas. So get connected and enjoy special rewards!
            </p>
            <h3>Current game benefits include</h3>
            <p>
              Generation Zero: Gain instant access to the Cyberpunk outfit as
              well as the ability to activate and play special in-game
              Assignments.
            </p>
            <p>
              Second Extinction: Exclusive rewards for Second Extinction are to
              be announced but rest assured they'll blow your socks off.
            </p>
          </ApexConnectInfo>
        </Content>
      </Container>
    </React.Fragment>
  )
}

export default ApexConnectPage

const Container = styled.section`
  ${props => props.theme.gridContainer()}

  margin-bottom: ${rem(80)};
  text-align: center;
`

const Title = styled.h1`
  ${props => props.theme.ffSecondarySet()}

  color: ${props => props.theme.colorYellow};
  font-size: ${rem(92)};
  word-break: break-word;
`

const Content = styled.div`
  margin-bottom: ${rem(40)};
`

const ApexConnectIframe = styled.iframe`
  width: 100%;
  height: 100vh;
`

const ApexConnectInfo = styled.div`
  margin: ${rem(40)} auto;
  max-width: 65ch;
  color: ${props => props.theme.colorGreyLightest};
  text-align: left;

  h3,
  p {
    margin-bottom: ${rem(20)};
  }
`
